
const cn ={ 
    home:{
            "language":"语言",
            "Logout":"退出登录",
            "config":"系统配置",
            "historicalsession":"历史会话",
            "Pleaseclickonthehistoricalsessionoropenanewsession":"请点击历史会话或开启新的会话",
            "cloct":"收藏",
            "last":"上一篇",
            "next":"下一篇",
            "none":"暂无更多了~",
            "Pleaseenteratitle":"请输入标题",
            "Pleaseenterthemaintext":"请输入正文",
            "reply":"回复",
            "hisC":"历史对话",
            "vip":"会员",
            "DoujinWork":"创作",
            "AIPlate":"AI",
            "Voice":"语音",
            "Setting":"设置",
            "Pleasesetthevoiceparametersfirst":"请先设置语音参数",
            "Newconversation":"新的对话",
            "Enterthetextthatneedstobeconvertedtovoice":"输入需要转语音的文本",
            "Tellmesomething":"来说点什么吧",
            "TitleAuthor":"书名、作者",
            "Login":"登录",
            "phone":"用户名",
            "password":"密码",
            "Signup":"注册",
            "HomePage":"首页",
            "Category":"分类",
            "Rankings":"排行榜",
            "Writers":"作家专区",
            "Translators":"译者专区",
            "Community":"社区",
            "Alreadyhaveanaccount":"已有账号？",
            "Loginnow":"立即登录",
            "Donthaveanaccount":"还没有注册账号？",
            "Registernow":"立即注册",
            "Pleaseenterthephone":"请输入用户名",
            "Pleaseenterthepassword":"请输入密码",
            "Pleaseentertheverificationcode":"请输入验证码",
            "TopPicksoftheWeek":"本周强推",
            "PopularRecommendations" :"热门推荐" ,
            "ClickRankings" :"点击榜单",
            "FeaturedRecommendations" :"精品推荐",
            "NewBookRankings" :"新书榜单",
            "LatestUpdates" :"最新更新" ,
            "MonthlyRecommendations" :"月度推荐" ,
            "ViewMore" :"查看更多" ,
            "WorksChannel":"作品方向",
            "Formen":"男频",
            "Forwomen":"女频",
            "WorkCategories":"作品分类",
            "All":"不限",
            "Status":"是否完结" ,
            "Ongoing":"连载中" ,
            "Completed":"已完结" ,
            "Wordcount":"作品字数",
            "words30":"30万字以下",
            "words30t50":"30-50万字",
            "words50t100":"50-100万字",
            "words100":"100万字以上",
            "Updated":"更新时间" ,
            "days3":"三日内" ,
            "days7":"七日内" ,
            "days15":"半月内" ,
            "days30":"一月内" ,
            "SortingMethods":"排序方式" ,
            "UpdateTime":"更新时间" ,
            "TotalWordCount":"总字数" ,
            "Clicks":"点击量" ,
            "Number":"序号" ,
            "Rank":"排名" ,
            "Category1":"类别" ,
            "Title":"书名" ,
            "LatestChapter":"最新章节", 
            "Author":"作者" ,
            "WordCount":"字数",
            "UpdateRankings":"更新榜",
            "ViewMore2":"展开",
            "Collapse":"收起",
             "Read":"点击阅读",
            "AddtoLibrary":"加入书架",
            "RequestUpdates":"催更",
            "Chapter":"章",
            "words":"字",
            "Contents":"全部目录",
            "Comment":"作品评论区",
            "PostComment":"发表评论",
            "Commentnow":"我来说两句",
            "Comment2":"发表",
            "SimilarRecommendations":"同类推荐",
            "TableofContents":"目录",
            "Pages":"书页",
            "PreviousChapter":"上一章",
            "NextChapter":"下一章",
            "authorLoginTitle":"我是网络小说写手，我要成为小说阅读网作者",
            "AuthorPenName":"作者笔名",
            "AuthorPenNameTips":"长度2-8位的中英文",
            "Phone":"手机号码",
            "PhoneTips":"小说阅读网的编辑会通过这个号码与您联系",
            "Email":"电子邮箱",
            "EmailTips":"长度为2-15位的中英文、数字",
            "Category2":"作品方向",
            "Category2Tips":"请选择作品方向",
            "WritingBtn":"立即开始您的创作生涯",
            "translatorLoginTitle":"我是网络小说译者，我要成为小说阅读网译者",
            "translatorPenName":"译者笔名",
            "ForWriters":"作者专区",
            "Workspace":"工作台",
            "DaysofCreation":"创作天数",
            "NumberofWorks":"作品数",
            "WordCount2":"累计字数",
            "NumberofFans":"粉丝数",
            "AccumulatedEarnings":"累计收益",
            "Cover":"封面",
            "WorkDirection":"作品方向",
            "Update":"更新状态",
            "AuthorBio":"作者简介",
            "TranslatorBio":"作者简介",
            "Cancel":"取消",
            "Save":"保存",
            "Homepage":"主页",
            "Follow":"关注",
            "Unfollow":"取消关注",
            "MyWorks":"我的作品",
            "CreateWorks":"创建作品",
            "Status2":"状态",
            "Edit":"编辑",
            "ChapterManagement":"章节管理",
            "Publish":"发布",
            "Remove":"下架",
            "ChapterList":"章节列表",
            "CreateaChapter":"创建章节",
            "Title2":"章节名",
            "Delete":"删除",
            "NovelIntroduction":"小说介绍",
            "Create":"创建",
            "AddChapter":"章节新增",
            "Pleaseenterchaptername":"请输入章节名",
            "Pleaseenterchaptercontent":"请输入章节内容",
            "Pleaseselectreferencefortranslation":"请选择参考的翻译作品",
            "referencefortranslation":"参考作品",
            "CancelSelect":"取消选择",
            "ContractManagement":"签约管理",
            "ContractManagement1":"签约管理",
            "SignContractnow":"立即签约",
            "Contract1":"成为创作者",
            "Contract11":"成为小说创作者，参与小说创作计划",
            "Contract2":"签约",
            "Contract22":"达成目标进行签约",
            "Contract3":"持续发布作品获取收益",
            "Contract33":"签约后发布作品，获得丰厚作品收益",
            "EditingInformation":"编辑资料",
            "operation":"操作",
            "Pleaseselectacategory":"请选择分类"
    }
}
export default cn