import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from '@/router'
import '@/assets/styles/base.css'
import '@/assets/styles/main.css'
import i18n from './lang/index'
// import '@/assets/scss/style.scss'
import vuetyped from 'vue3typed'
const app = createApp(App)

app.use(ElementPlus)

// 挂载打字机的全局方法
app.use(vuetyped)



app.use(router)

app.use(i18n)

app.mount('#app')

