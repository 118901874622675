import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  // createWebHistory 路由模式路径不带#号(生产环境下不能直接访问项目，需要 nginx 转发)
  // createWebHashHistory 路由模式路径带#号
  history: createWebHashHistory(), 
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register')
    },
    {
      path: '/H5login',
      name: 'H5login',
      component: () => import('@/views/H5Login')
    },
    {
      path: '/H5register',
      name: 'H5register',
      component: () => import('@/views/H5Register')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login')
    },
    {
      path: '/feadback',
      name: 'feadback',
      component: () => import('@/views/FeadBack')
    },
    {
      path: '/news/:id',
      name: 'news',
      component: () => import('@/views/News')
    },
    {
      path: '/bookclass',
      name: 'bookclass',
      component: () => import('@/views/BookClass')
    },
    {
      path: '/book_rank',
      name: 'bookRank',
      component: () => import('@/views/BookRank')
    },
    {
      path: '/book/:id',
      name: 'book',
      component: () => import('@/views/Book')
    },
    {
      path: '/chapter_list/:bookId',
      name: 'chapterList',
      component: () => import('@/views/ChapterList')
    },
    {
      path: '/book/:id/:chapterId',
      name: 'bookContent',
      component: () => import('@/views/BookContent')
    },
    {
      path: '/user/setup',
      name: 'userSetup',
      component: () => import('@/views/UserSetup')
    },
    {
      path: '/user/comment',
      name: 'userComment',
      component: () => import('@/views/UserComment')
    },
    {
      path: '/user/books',
      name: 'userBooks',
      component: () => import('@/views/userBooks')
    },
    {
      path: '/user/likes',
      name: 'userLikes',
      component: () => import('@/views/userLikes')
    },
    
    //作者相关
    {
      path: '/author/dashboard',
      name: 'authorDashboard',
      component: () => import('@/views/author/Dashboard')
    },
    {
      path: '/author/signing',
      name: 'authorSigning',
      component: () => import('@/views/author/authorSigning')
    },
    {
      path: '/author/bookList',
      name: 'authorBookList',
      component: () => import('@/views/author/BookList')
    },


    {
      path: '/author/register',
      name: 'authorRegister',
      component: () => import('@/views/author/Register')
    },
    {
      path: '/author/authorInfo',
      name: 'authorInfo',
      component: () => import('@/views/author/authorInfo')
    },
    {
      path: '/author/book_add',
      name: 'authorBookAdd',
      component: () => import('@/views/author/BookAdd')
    },
    {
      path: '/author/book_update',
      name: 'authorBookUpdate',
      component: () => import('@/views/author/BookUpdate')
    },
    {
      path: '/author/chapter_list',
      name: 'authorChapterList',
      component: () => import('@/views/author/ChapterList')
    },
    {
      path: '/author/chapter_add',
      name: 'authorChapterAdd',
      component: () => import('@/views/author/ChapterAdd')
    },
    {
      path: '/author/chapter_update',
      name: 'authorChapterUpdate',
      component: () => import('@/views/author/ChapterUpdate')
    },
    //译者相关
    {
      path: '/translator/dashboard',
      name: 'translatorDashboard',
      component: () => import('@/views/translator/Dashboard')
    },
    {
      path: '/translator/signing',
      name: 'translatorSigning',
      component: () => import('@/views/translator/translatorSigning')
    },
    {
      path: '/translator/bookList',
      name: 'translatorBookList',
      component: () => import('@/views/translator/BookList')
    },
    {
      path: '/translator/register',
      name: 'translatorRegister',
      component: () => import('@/views/translator/Register')
    },
    {
      path: '/translator/translatorInfo',
      name: 'translatorInfo',
      component: () => import('@/views/translator/translatorInfo')
    },


    {
      path: '/translator/book_add',
      name: 'translatorBookAdd',
      component: () => import('@/views/translator/BookAdd')
    },
    {
      path: '/translator/book_update',
      name: 'translatorBookUpdate',
      component: () => import('@/views/translator/BookUpdate')
    },
    {
      path: '/translator/chapter_list',
      name: 'translatorChapterList',
      component: () => import('@/views/translator/ChapterList')
    },
    {
      path: '/translator/chapter_add',
      name: 'translatorChapterAdd',
      component: () => import('@/views/translator/ChapterAdd')
    },
    {
      path: '/translator/chapter_update',
      name: 'translatorChapterUpdate',
      component: () => import('@/views/translator/ChapterUpdate')
    },
    {
      path: '/community/index',
      name: 'communityIndex',
      component: () => import('@/views/community/index')
    },
    {
      path: '/community/h5index',
      name: 'communityh5Index',
      component: () => import('@/views/community/h5index')
    },
    {
      path: '/community/:id',
      name: 'communityContent',
      component: () => import('@/views/community/content')
    },
    {
      path: '/communityH5/:id',
      name: 'communityH5Content',
      component: () => import('@/views/community/h5content')
    },
    {
      path: '/community/ai',
      name: 'communityAi',
      component: () => import('@/views/chat/index')
    },
    {
      path: '/community/h5ai',
      name: 'communityh5Ai',
      component: () => import('@/views/chat/h5index')
    },
    {
      path: '/community/voice',
      name: 'communityVoice',
      component: () => import('@/views/chat/voice')
    },
    {
      path: '/community/h5voice',
      name: 'communityh5Voice',
      component: () => import('@/views/chat/h5Voice')
    },
  ]
})

// 解决 vue 中路由跳转时，总是从新页面中间开始显示
router.afterEach((to,from,next) => {
  window.scrollTo(0,0)
})

export default router