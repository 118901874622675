const en ={
    home:{
            "language":"Language",
            "Logout":"Log out",
            "config":"Config",
            "historicalsession":"historicalsession",
            "Pleaseclickonthehistoricalsessionoropenanewsession":"Please click on the historical conversation or open a new conversation",
            "cloct":"cloct",
            "last":"last",
            "next":"next",
            "none":"none",
            "reply":"reply",
            "Pleaseenteratitle":"Please enter a title",
            "Pleaseenterthemaintext":"Please enter the main text",
            "hisC":"Historical conversation",
            "vip":"vip",
            "DoujinWork":"Create",
            "AIPlate":"AI",
            "Voice":"Voice",
            "Setting":"Setting",
            "Pleasesetthevoiceparametersfirst":"Please set the voice parameters first",
            "Newconversation":"New conversation",
            "Tellmesomething":"Tell me something",
            "Enterthetextthatneedstobeconvertedtovoice":"Enter the text that needs to be converted to voice",
            "TitleAuthor":"Title,Author",
            "Login":"Login",
            "phone":"username",
            "password":"password",
            "Signup":"Signup",
            "HomePage":"HomePage",
            "Category":"Category",
            "Rankings":"Rankings",
            "Writers":"Writers",
            "Translators":"Translators",
            "Community":"Community",
            "Alreadyhaveanaccount":"Already have an account?",
            "Loginnow":"Login now",
            "Donthaveanaccount":"Don’t have an account?",
            "Registernow":"Register now",
            "Pleaseenterthephone":"Please enter the username",
            "Pleaseenterthepassword":"Please enter the password",
            "Pleaseentertheverificationcode":"Please enter the verification code",
            "TopPicksoftheWeek":"Top Picks of the Week",
            "PopularRecommendations":"Popular Recommendations",
            "ClickRankings":"Click Rankings",
            "FeaturedRecommendations":"Featured Recommendations",
            "NewBookRankings":"New Book Rankings",
            "LatestUpdates":"Latest Updates",
            "MonthlyRecommendations":"Monthly Recommendations",
            "ViewMore":"View More",
            "WorksChannel":"Works Channel",
            "Formen":"For men",
            "Forwomen":"For women",
            "WorkCategories":"Work Categories",
            "All":"All",
            "Status":"Status" ,
            "Ongoing":"Ongoing" ,
            "Completed":"Completed" ,
            "Wordcount":"Word count",
            "words30":"Below 300,000 words",
            "words30t50":"300,000-500,000 words",
            "words50t100":"500,000-1,000,000 words",
            "words100":"More than 10000 words",
            "Updated":"Updated" ,
            "days3":"Within 3 days",
            "days7":"7 days",
            "days15":"15 days",
            "days30":"30 days",
            "SortingMethods":"Sorting Methods" ,
            "UpdateTime":"Update Time",
            "TotalWordCount":"Total Word Count",
            "Clicks":"Clicks" ,
            "Number":"Number", 
            "Rank":"Rank" ,
            "Category1":"Category", 
            "Title":"Title", 
            "LatestChapter":"Latest Chapter", 
            "Author":"Author", 
            "WordCount":"Word Count", 
            "UpdateRankings":"Update Rankings",
            "ViewMore2":"View More",
            "Collapse":"Collapse",
            "Read":"Read",
            "AddtoLibrary":"Add to Library",
            "RequestUpdates":"Request Updates",
            "Chapter":"Chapter",
            "words":"words",
            "Contents":"Contents",
            "Comment":"Comment",
            "PostComment":"Post Comment",
            "Commentnow":"Comment now",
            "Comment2":"Comment",
            "SimilarRecommendations":"Similar Recommendations",
            "TableofContents":"Table of Contents",
            "Pages":"Pages",
            "PreviousChapter":"Previous Chapter",
            "NextChapter":"Next Chapter",
            "authorLoginTitle":"I am a web novel writer, and I want to become an author",
            "AuthorPenName":"Author Pen Name",
            "AuthorPenNameTips":"2-8 characters in English or Chinese",
            "Phone":"Phone",
            "PhoneTips":"We will contact you through this number",
            "Email":"Email",
            "EmailTips":"2-15 characters in English or Chinese",
            "Category2":"Category",
            "Category2Tips":"Please select work categories",
            "WritingBtn":"Start you writing career now!",
            "translatorLoginTitle":"I am a web novel translator, and I want to become an translator",
            "translatorPenName":"Translator Pen Name",
            "ForWriters":"For Writers",
            "Workspace":"Workspace",
            "DaysofCreation":"Days of Creation",
            "NumberofWorks":"Number of Works",
            "WordCount2":"Word Count",
            "NumberofFans":"Number of Fans",
            "AccumulatedEarnings":"Accumulated Earnings",
            "Cover":"Cover",
            "WorkDirection":"Work Direction",
            "Update":"Update",
            "AuthorBio":"Author Bio",
            "TranslatorBio":"Translator Bio",
            "Cancel":"Cancel",
            "Save":"Save",
            "Homepage":"Homepage",
            "Follow":"Follow",
            "Unfollow":"Unfollow",
            "MyWorks":"My Works",
            "CreateWorks":"Create Works",
            "Status2":"Status",
            "Edit":"Edit",
            "ChapterManagement":"Chapter Management",
            "Publish":"Publish",
            "Remove":"Remove",
            "ChapterList":"Chapter List",
            "CreateaChapter":"Create a Chapter",
            "Title2":"Title",
            "Delete":"Delete",
            "NovelIntroduction":"Novel Introduction",
            "Create":"Create",
            "AddChapter":"Add Chapter",
            "Pleaseenterchaptername":"Please enter chapter name",
            "Pleaseenterchaptercontent":"Please enter chapter content",
            "Pleaseselectreferencefortranslation":"Please select reference for translation",
            "referencefortranslation":"reference works",
            "CancelSelect":"Cancel Select",
            "ContractManagement":"Contract Management",
            "ContractManagement1":"Contract",
            "SignContractnow":"Sign Contract  now",
            "Contract1":"Become a Creator",
            "Contract11":"Become a Novel Creator and Participate in Novel Creation Plan",
            "Contract2":"Sign Contract",
            "Contract22":"Sign Contract upon Achieving Goals",
            "Contract3":"Continuous Publication of Works for Earnings",
            "Contract33":"Public Works after Signing the Contract and Receive Substantial Earnings",
            "EditingInformation":"Edit Information",
            "operation":"operation",
            "Pleaseselectacategory":"Please select a category"
        },
}


export default en